import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import LCO from './images/lcoquery.pdf'
import { Form } from "./components/Form";
import Slider from 'react-slick'
import img01 from './images/contact-us.webp';
import { Effectarrow } from './components/Effectarrow';

export const Contactus = () => {
    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
    return (
        <>
            <Header />
            <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner'>
                    <div className='leftarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='home-slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='sliderrow align-items-center'>
                                                <div className='slideimg'>
                                                    <img src={img01} alt='Banner Netplus' />
                                                </div>
                                                <div className='slidecont'>
                                                    <div className='circle01'>
                                                        <div className='mngcircle'>
                                                            <div className='circlimg'>
                                                                <h2>Contact Us and Experience First-class Assistance</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='contactpage' className='pb-0 position-relative'>
                <div className='contactpage'>
                    <div className='leftarrow '>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='title'>
                                    <span>Contact us</span>
                                </div>
                                <div className='contactoverview'>
                                    <div className='contactbox'>
                                        <ul>
                                            <li><span>Toll Free</span><p><a href='tel:18001026602'><strong>1800-1026-602</strong></a></p></li>

                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <ul>
                                            <li><span>Customer Support </span><p><a href='tel:+919217092170'><strong>+91 92170-92170</strong></a></p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='contactoverview contstn'>
                                    <div className='contactbox col-12'>
                                        <div className='office'>
                                            <h4>Fastway Transmissions Pvt. Ltd.</h4>
                                            <div className='officebox'>
                                                <ul>
                                                    <li>Address : Grand Walk Mall, 5th Floor,Opp. Gurdev Hospital,Ferozpur Road, Ludhiana- 141002</li>
                                                    <li>Telephone : <a href='tel:+911614044444'>+91-161-4044444</a></li>
                                                </ul>
                                                <ul>
                                                    <li><strong>Customer Care @ Fastway</strong> (Available 24*7, 365 days a year)Call Centre (Customer Support)</li>
                                                    <li>Toll Free : <a href='tel:18001026602'>1800-1026-602</a></li>
                                                    <li>Mobile No : <a href='tel:+919217092170'>+91 92170-92170</a></li>
                                                    <li>Email : <a href='mailto:customercare@fastway.in'>customercare@fastway.in</a></li>
                                                </ul>
                                                <ul>
                                                    <li><strong>Nodal Officer : Mr. Vishal Sabharwal</strong></li>
                                                    <li>Email : <a href='mailto:nodalofficer@fastway.in'>nodalofficer@fastway.in</a></li>
                                                    <li>Telephone : <a href='tel:+919814969500'>+91-9988400953</a></li>
                                                    <li>(Monday to Saturday - 10:00 to 18:00 Hrs)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='locateus' className='position-relative'>
                <div className='contactpage'>
                    <div className='leftarrow '>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='title'>
                                    <span>Locate us</span>
                                    <h3>Zonal Sales & Service Offices</h3>
                                </div>
                                <div className='contactoverview'>
                                    <div className='contactbox'>
                                        <h3>AMRITSAR</h3>
                                        <ul>
                                            <li><span>Address</span> <p>1st Floor, Netplus Broadband Services Pvt. Ltd., Inside Alpha International City, Daburji, Amritsar</p></li>
                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <h3>Bathinda </h3>
                                        <ul>
                                            <li><span>Address</span><p>1st Floor, Building No. B77, Above IDBI Bank, Teshwar Complex, Hajiratan Chowk, Bathinda</p></li>
                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <h3>Chandigarh </h3>
                                        <ul>
                                            <li><span>Address</span><p>Plot No 17, Industrial Area, Phase 1, Chandigarh</p></li>
                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <h3>Jalandhar </h3>
                                        <ul>
                                            <li><span>Address</span><p>6th Floor, Grand Mall Building, Near B. M. C. Chowk, Jalandhar</p></li>
                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <h3>Ludhiana </h3>
                                        <ul>
                                            <li><span>Address</span><p>5th Floor, Grand Walk Mall, Ferozepur Road, Ludhiana</p></li>
                                        </ul>
                                    </div>
                                    <div className='contactbox'>
                                        <h3>Patiala </h3>
                                        <ul>
                                            <li><span>Address</span><p>3rd Floor, SCO 144, Chhotti Baradari, Patiala</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='downloadpdf' className='p-0'>
                <div className='contactpage'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='contactoverview'>
                                    <div className='contactbox gradient'>
                                        <ul>
                                            <li><p><a href='https://fastway.in/lco/' target='_blank'>LCO Complaints</a></p></li>

                                        </ul>
                                    </div>
                                    <div className='contactbox gradient'>
                                        <ul>
                                            <li><p><a href={LCO} target='_blank'>LCO Query</a></p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}

            <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
            <Footer />
        </>
    )
}
